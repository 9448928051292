import {Pages} from "./Pages";
import "jquery-validation";
import "jquery-validation/dist/additional-methods";
import "jquery-validation/dist/localization/messages_ru";

export class AuthForm extends Pages{

    initPageHandlers(params) {
        super.initPageHandlers(params);

        $("#auth_form").validate(
            {
                rules: {
                    "login": {
                        required: true,
                    },
                    "password": {
                        required: true,
                    },
                },
                messages: {
                    "login": {
                        required: "Укажите Логин",
                    },
                    "password": {
                        required: "Укажите пароль",
                    },
                },
            },
        );



    }
}
